<template>
    <div v-if="inquiry">
        <v-divider class="border-dark d-none d-md-block" style="border-width: 2px 0 0" />
        <div class="pb-24px pt-md-24px border-b">
            <v-row align="end" align-md="start">
                <v-col>
                    <div class="font-size-18 font-size-md-24 font-weight-medium break-words pb-10px">{{ inquiry.subject }}</div>
                    <v-row align="center" class="ma-n2px ma-md-n4px">
                        <v-col cols="auto" class="pa-2px pa-md-4px">
                            <v-avatar :size="$vuetify.breakpoint.mdAndUp ? 30 : 24" color="grey" />
                        </v-col>
                        <v-col class="pa-2px pa-md-4px">
                            <div class="txt txt--sm">{{ inquiry?.user.nickname }}</div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="auto">
                    <div class="txt txt--xs">{{ inquiry?.createdAt.toDate() }}</div>
                </v-col>
            </v-row>
        </div>
        <div class="py-24px">
            <div class="pb-10px pb-md-20px">
                <div class="txt txt--sm">상품 : {{ inquiry?.product?.name }}</div>
            </div>
            <div v-if="inquiry.content" class="min-h-100px min-h-md-150px">
                <div v-html="inquiry.content.replace(/\n/g, '<br>')" />
            </div>
            <div class="pt-10px">
                <v-row align="center" class="ma-n4px">
                    <v-col v-for="(image, index) in inquiry.images" :key="index" cols="auto">
                        <a :href="image" download class="d-inline-block">
                            <v-card tile class="attachment-img">
                                <v-btn fab color="primary" class="attachment-img__close"><v-icon x-small color="white">file_download</v-icon></v-btn>
                                <v-img :src="image" :aspect-ratio="1 / 1" width="72">
                                    <template #placeholder>
                                        <v-overlay absolute opacity="0.1"> <v-icon>mdi-image-broken-variant</v-icon> </v-overlay>
                                    </template>
                                </v-img>
                            </v-card>
                        </a>
                    </v-col>
                        <!-- <v-col cols="auto" class="d-flex pa-4px">
                            <span class="material-icons-outlined font-size-16"> file_download </span>
                        </v-col>
                        <v-col cols="auto" class="pa-4px">
                            <div class="txt txt--xs txt--dark">첨부된 파일입니다.jpeg</div>
                        </v-col> -->
                </v-row>
            </div>
        </div>
        <v-divider v-if="!inquiry.reply" />
        <template v-if="inquiry.reply">
            <div class="mx-n12px mx-md-0">
                <v-divider class="border-dark d-none d-md-block" style="border-width: 2px 0 0" />
                <v-divider class="d-md-none" />
                <v-divider class="grey lighten-5 d-md-none" style="border-width: 8px 0 0" />
            </div>
            <div class="py-24px">
                <div :class="$vuetify.breakpoint.mdAndUp ? '' : 'border-b'" class="pb-16px pb-md-24px">
                    <v-row align="center" class="ma-n10px">
                        <v-col cols="auto" class="pa-10px">
                            <div class="txt txt--xl txt--dark font-weight-bold">답변내역</div>
                        </v-col>
                        <v-col cols="auto" class="pa-10px">
                            <div class="txt txt--xs">답변일 : {{ inquiry?.repliedAt ? inquiry.repliedAt.toDate() : "" }}</div>
                        </v-col>
                    </v-row>
                </div>
                <div class="min-h-100px min-h-md-150px">
                    <div v-html="inquiry.reply.replace(/\n/g, '<br>')" />
                </div>
            </div>
        </template>

        <div class="btn-wrap btn-wrap--lg mx-n12px mx-md-0px">
            <v-row justify="space-between" class="ma-0px ma-md-n4px">
                <v-col v-if="!inquiry.reply" cols="6" md="auto" class="pa-0px pa-md-4px">
                    <v-btn v-bind="{ ...btn_primary }" class="v-size--xx-large w-100 min-w-md-140px" @click="modify">수정</v-btn>
                </v-col>
                <v-col cols="6" md="auto" class="pa-0px pa-md-4px">
                    <v-btn v-bind="{ ...btn_secondary }" class="v-size--xx-large min-w-md-140px d-none d-md-block" @click="remove">삭제</v-btn>
                    <v-btn v-bind="{ ...btn_primary3 }" class="v-size--xx-large w-100 d-md-none" @click="remove">삭제</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import { btn_primary, btn_primary3, btn_secondary, INQUIRY_TYPES } from "@/assets/variables";

export default {
    components: {},
    data: () => {
        return {
            INQUIRY_TYPES,
            btn_primary,
            btn_primary3,
            btn_secondary,

            inquiry: null,
            preview: [],
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                await this.getInquiry();
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        // list() {
        //     this.$router.push({ path: "./inquiry" });
        // },

        getInquiry() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get(`/api/v1/shop/inquires/${this.$route.params._inquiry}`);

                    this.inquiry = res.data.inquiry;

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
        modify(){
            this.$router.push({ path: `./create/${this.inquiry._id}`})
        },
        async remove(){
            try{
                if(confirm("정말 삭제하시겠습니까?")){
                    let { inquiry } = await api.v1.me.inquires.delete({ _id: this.inquiry._id });

                    if(inquiry._images.length) {
                        await Promise.all(
                            inquiry._images.map(async(_file)=>{
                                await api.v1.files.delete({ _id: _file });
                            })
                        )
                    }
                } else{
                    return;
                }
                alert("삭제가 완료되었습니다.");
                this.$router.go(-1);
            } catch(error){
                this.$handleError(error);
            }
        }
    },
};
</script>
